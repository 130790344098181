import React, { useEffect } from 'react';

import { Auth, Database } from '../../firebase.config.js';
import {
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithPopup,
} from 'firebase/auth';
import './index.css';
import { get, ref, set } from 'firebase/database';

const Index = () => {
    useEffect(() => {
        onAuthStateChanged(Auth, async (user) => {
            if (user !== null) {
                const userDB = ref(Database, 'users/' + user.uid);
                await get(userDB).then(async (snapshot) => {
                    if (snapshot.exists()) {
                        window.location.href = '/home';
                    } else {
                        await set(userDB, {
                            displayName: user.displayName,
                            email: user.email,
                            classes: [],
                        }).catch((err) => {
                            console.log(err);
                        });
                        window.location.href = '/home';
                    }
                });
            }
        });
    });
    const Authentication = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(Auth, provider).then(() => {
            window.location.href = '/home'
        });
    };

    // const AuthUsername = (email, password) => {
    //     signInWithEmailAndPassword(Auth, email, password);
    // };

    return (
        <div className='main-container'>
            <div className='sub-container'>
                <div className='sky'>
                    <div className='stars'></div>
                    <div className='stars2'></div>
                    <div className='stars3'></div>
                    <div className='comet'></div>
                </div>
            </div>

            <div className='login-container'>
                <h1 id='login-title1'>Đăng nhập</h1>
                <h2 id='login-title2'>Để tiếp tục hành trình của bạn</h2>
                <input id='username' type='text' placeholder='Tên đăng nhập' />
                <input id='password' type='password' placeholder='Mật khẩu' />
                <br></br>
                {/* <button id='login' onClick={}>Đăng nhập</button> */}
                <button id='login-fpt' onClick={Authentication}>
                    Tiếp tục với mail FPT
                </button>
            </div>
        </div>
    );
};

export default Index;
