import React from 'react';
import { onValue, remove, update, push, child, ref, get, set } from 'firebase/database';
import { Stage, Layer, Line, Rect, Text, Image, Transformer } from 'react-konva';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Auth, Database } from '../../firebase.config';
import { onAuthStateChanged } from 'firebase/auth';
import { Html } from 'react-konva-utils';
// import ChatBot from '../ChatBot/index'
import simplify from 'simplify-js';
import useImage from 'use-image';
import Konva from 'konva';
import './index.css';

// ----------------------------------------------------------------
import ChatBot from '../ChatBot/index.jsx';
// import ShowVideo from './video';
// ----------------------------------------------------------------

const ImageKonva = ({ base64, x, y, id, type, owner, scaleX, scaleY, onDragEnd, content }) => {
    const [image] = useImage(base64);
    if (x === undefined) x = 0;
    if (y === undefined) y = 0;
    return (
        <Image
            image={image}
            id={id}
            x={x}
            y={y}
            type={type}
            owner={owner}
            scaleX={scaleX}
            scaleY={scaleY}
            onDragEnd={onDragEnd}
            content={content}
        />
    );
};

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
}

class Paper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLine: {
                properties: {
                    userId: null,
                    type: '',
                    lock: false,
                    x: 0,
                    y: 0,
                },
                options: {
                    stroke: null,
                    strokeWidth: null,
                },
            },
            math: '',
            lines: {},
            user: null,
            strokeWidth: 5,
            cursorPos: [0, 0],
            // stroke: "#d9d9d9",
            stroke: '#000000',
            showUsername: false,
            usernameOwnLine: '',
            permission: 'student',
            cursorStyle: 'default',
            // ---| Code Tú đừng chửi tú ngu :) |--------------------------------------------------
            chatBotStatus: 'ChatContainer',
            controlStatus: 'controlContainer-inactive',
            showVideo: '',
        };

        this.canvasContainerRef = React.createRef();
        this.usernameOwnLine = React.createRef();
        this.fileImportRef = React.createRef();
        // this.mathFomulaRef = React.createRef();
        this.tranformRef = React.createRef();
        // this.onChangeRef = React.createRef();
        this.textareaRef = React.createRef();
        this.selectRef = React.createRef();
        this.eraserRef = React.createRef();
        this.unlockRef = React.createRef();
        this.stageRef = React.createRef();
        this.layerRef = React.createRef();
        this.clearRef = React.createRef();
        this.imageRef = React.createRef();
        this.lockRef = React.createRef();
        // this.undoRef = React.createRef();
        this.drawRef = React.createRef();
        this.textRef = React.createRef();
        this.rectRef = React.createRef();
        this.mathRef = React.createRef();
        this.copyRef = React.createRef();
        this.lineRef = React.createRef();
        this.refDB = child(ref(Database, 'classes'), this.props.router.params.classId);
        this.boardDB = child(this.refDB, 'board');
        this.userDB = ref(Database, 'users');
    }

    changeMode = (mode) => {
        const tranformElm = this.tranformRef.current;
        const stageElm = this.stageRef.current;
        // const canvasContainerElm  = this.canvasContainerRef.current;
        this.stageRef.current.off('mousedown touchstart pointerdown');
        window.oncontextmenu = (e) => {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        };
        this.stageRef.current.on('mousedown touchstart pointerdown', (e) => {
            // e.preventDefault()
            if (e.evt.button === 2) {
                stageElm.startDrag();
            } else {
                mode(e);
            }
        });
        if (mode === this.selectMode) this.setState({ showUsername: true });
        else this.setState({ showUsername: false });
        stageElm.draggable(false);
        window.onkeydown = null;
        tranformElm.nodes([]);
    };
    drawMode = () => {
        const stageElm = this.stageRef.current;
        const { stroke, strokeWidth } = this.state;
        const { x, y } = {
            x: (stageElm.getPointerPosition().x - stageElm.getX()) / stageElm.scaleX(),
            y: (stageElm.getPointerPosition().y - stageElm.getY()) / stageElm.scaleY(),
        };
        const currentLineKey = push(this.boardDB).key;
        const currentLineValue = {
            properties: {
                userId: this.state.user.uid,
                lock: false,
                type: 'line',
            },
            options: { stroke, strokeWidth },
            points: [x, y, x, y],
        };
        this.setState({ currentLine: currentLineValue });

        stageElm.on('mousemove touchmove pointermove', () => {
            const x = (stageElm.getPointerPosition().x - stageElm.getX()) / stageElm.scaleX();
            const y = (stageElm.getPointerPosition().y - stageElm.getY()) / stageElm.scaleY();
            let currentLine = this.state.currentLine;
            if (currentLine.points === undefined) return;
            currentLine.points = currentLine.points.concat([x, y]);
            this.setState({ currentLine });
        });

        stageElm.on('mouseup touchend pointerup', async () => {
            stageElm.off(
                'mousedown touchdown pointerdown mousemove touchmove pointermove mouseup touchend pointerup'
            );
            let currentLine = this.state.currentLine;
            let simplifyPoint = [];
            for (let i = 0; i < currentLine.points.length; i += 2) {
                simplifyPoint.push({
                    x: currentLine.points[i],
                    y: currentLine.points[i + 1],
                });
            }
            simplifyPoint = simplify(simplifyPoint, 1, true);
            currentLine.points = simplifyPoint.map((point) => [point.x, point.y]).flat();
            update(this.boardDB, { [currentLineKey]: currentLine });
            this.setState({
                currentLine: {
                    properties: {
                        userId: this.state.user.uid,
                        lock: false,
                    },
                    options: {
                        stroke,
                        strokeWidth,
                    },
                },
            });
            stageElm.on('mousedown touchdown pointerdown', this.drawMode);
        });
    };
    lineMode = () => {
        const stageElm = this.stageRef.current;
        const { stroke, strokeWidth } = this.state;
        const { x, y } = {
            x: (stageElm.getPointerPosition().x - stageElm.getX()) / stageElm.scaleX(),
            y: (stageElm.getPointerPosition().y - stageElm.getY()) / stageElm.scaleY(),
        };
        const currentLineKey = push(this.boardDB).key;
        const currentLineValue = {
            properties: {
                userId: this.state.user.uid,
                lock: false,
                type: 'line',
            },
            options: { stroke, strokeWidth },
            points: [x, y, x, y],
        };
        this.setState({ currentLine: currentLineValue });

        stageElm.on('mousemove touchmove pointermove', () => {
            const { x, y } = {
                x: (stageElm.getPointerPosition().x - stageElm.getX()) / stageElm.scaleX(),
                y: (stageElm.getPointerPosition().y - stageElm.getY()) / stageElm.scaleY(),
            };
            let currentLine = this.state.currentLine;
            if (currentLine.points === undefined) return;
            currentLine.points[2] = x;
            currentLine.points[3] = y;
            stageElm.batchDraw();
            this.setState({ currentLine });
        });

        stageElm.on('mouseup touchend pointerup', async () => {
            stageElm.off(
                'mousedown touchdown pointerdown mousemove touchmove pointermove mouseup touchend pointerup'
            );
            let currentLine = this.state.currentLine;
            // let simplifyPoint = [];
            // for (let i = 0; i < currentLine.points.length; i += 2) {
            //     simplifyPoint.push({
            //         x: currentLine.points[i],
            //         y: currentLine.points[i + 1],
            //     });
            // }
            // simplifyPoint = simplify(simplifyPoint, 1, true);
            // currentLine.points = simplifyPoint.map((point) => [point.x, point.y]).flat();
            update(this.boardDB, { [currentLineKey]: currentLine });
            this.setState({
                currentLine: {
                    properties: {
                        userId: this.state.user.uid,
                        lock: false,
                    },
                    options: {
                        stroke,
                        strokeWidth,
                    },
                },
            });
            stageElm.on('mousedown touchdown pointerdown', this.lineMode);
        });
    };
    selectMode = async (e) => {
        const stageElm = this.stageRef.current;
        const objectCheckSelected = (node, selectRect) => {
            const selectStartX = (selectRect.x - stageElm.getX()) / stageElm.scaleX();
            const selectStartY = (selectRect.y - stageElm.getY()) / stageElm.scaleY();
            const selectStopX = selectStartX + selectRect.width / stageElm.scaleX();
            const selectStopY = selectStartY + selectRect.height / stageElm.scaleY();
            const blacklistElm = new Set(['Rect', 'Transformer', 'Group']);
            const { points } = node.attrs;
            if (blacklistElm.has(node.getClassName())) return false;
            else if (points !== undefined) {
                for (let i = 0; i < points.length; i = i + 2) {
                    const objX = points[i];
                    const objY = points[i + 1];
                    const objXCheck =
                        Math.min(selectStartX, selectStopX) <= objX &&
                        objX <= Math.max(selectStartX, selectStopX);
                    const objYCheck =
                        Math.min(selectStartY, selectStopY) <= objY &&
                        objY <= Math.max(selectStartY, selectStopY);
                    if (objXCheck && objYCheck) return true;
                }
                return false;
            } else {
                const objectRect = node.getClientRect();
                //console.log(node.getAttrs(), objectRect);
                return Konva.Util.haveIntersection(selectRect, objectRect);
            }
        };
        const tranformElm = this.tranformRef.current;
        const node = new Set(tranformElm.nodes());
        if (e.target.getParent()?.getClassName() !== 'Transformer' && !node.has(e.target)) {
            window.onkeydown = null;
            const layerElm = this.layerRef.current;
            const rectElm = this.rectRef.current;
            tranformElm.nodes().forEach((node) => {
                node.draggable(false);
                node.off('dblclick dbltap');
            });
            tranformElm.nodes([]);

            let selectBoxStartX, selectBoxStartY, selectBoxStopX, selectBoxStopY;
            selectBoxStartX = selectBoxStopX =
                (stageElm.getPointerPosition().x - stageElm.getX()) / stageElm.scaleX();
            selectBoxStartY = selectBoxStopY =
                (stageElm.getPointerPosition().y - stageElm.getY()) / stageElm.scaleX();
            rectElm.visible(true);
            rectElm.setAttrs({
                x: selectBoxStartX,
                y: selectBoxStartY,
                width: 0,
                height: 0,
            });
            stageElm.on('mousemove touchmove pointermove', () => {
                rectElm.visible(true);
                selectBoxStopX =
                    (stageElm.getPointerPosition().x - stageElm.getX()) / stageElm.scaleX();
                selectBoxStopY =
                    (stageElm.getPointerPosition().y - stageElm.getY()) / stageElm.scaleY();
                rectElm.setAttrs({
                    x: Math.min(selectBoxStartX, selectBoxStopX),
                    y: Math.min(selectBoxStartY, selectBoxStopY),
                    width: Math.abs(selectBoxStopX - selectBoxStartX),
                    height: Math.abs(selectBoxStopY - selectBoxStartY),
                });
            });
            stageElm.on('mouseup touchend pointerup', () => {
                stageElm.off('mousemove touchmove pointermove mouseup touchend pointerup');
                const selectedElm = layerElm.getChildren((node) => {
                    return objectCheckSelected(node, rectElm.getClientRect());
                });
                //console.log(selectedElm);
                rectElm.visible(false);
                tranformElm.nodes(selectedElm);
                tranformElm.getLayer().batchDraw();
                selectedElm.forEach((node) => {
                    node.setAttr('draggable', true);
                });
                if (selectedElm.length === 1) {
                    if (selectedElm[0].attrs.type === 'text') {
                        const textareaElm = this.textareaRef.current;
                        const textElm = selectedElm[0];
                        textElm.on('dblclick dbltap', () => {
                            const currentTextKey = textElm.getAttr('id');
                            const currentTextValue = textareaElm.value;

                            this.setState({ showVideo: currentTextValue });
                            this.checkYoutubeLink(this.state.showVideo);

                            textElm.hide();
                            tranformElm.hide();
                            let textPosition = textElm.getAbsolutePosition();
                            let areaPosition = {
                                x:
                                    (stageElm.container().offsetLeft +
                                        textPosition.x -
                                        stageElm.getX()) /
                                    stageElm.scaleX(),
                                y:
                                    (stageElm.container().offsetTop +
                                        textPosition.y -
                                        stageElm.getY()) /
                                    stageElm.scaleY(),
                            };
                            textareaElm.value = textElm.text();
                            textareaElm.style.display = 'block';
                            textareaElm.style.position = 'absolute';
                            textareaElm.style.top = areaPosition.y + 'px';
                            textareaElm.style.left = areaPosition.x + 'px';
                            textareaElm.style.width =
                                textElm.width() - textElm.padding() * 2 + 'px';
                            textareaElm.style.height =
                                textElm.height() - textElm.padding() * 2 + 5 + 'px';
                            textareaElm.style.fontSize = textElm.fontSize() + 'px';
                            textareaElm.style.border = 'none';
                            textareaElm.style.padding = '0px';
                            textareaElm.style.margin = '0px';
                            textareaElm.style.overflow = 'hidden';
                            textareaElm.style.background = 'none';
                            textareaElm.style.outline = 'none';
                            textareaElm.style.resize = 'none';
                            textareaElm.style.lineHeight = textElm.lineHeight();
                            textareaElm.style.fontFamily = textElm.fontFamily();
                            textareaElm.style.transformOrigin = 'left top';
                            textareaElm.style.textAlign = textElm.align();
                            textareaElm.style.color = textElm.fill();
                            const rotation = textElm.rotation();
                            let transform = '';
                            if (rotation) {
                                transform += 'rotateZ(' + rotation + 'deg)';
                            }
                            let px = 0;
                            let isFirefox =
                                navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                            if (isFirefox) {
                                px += 2 + Math.round(textElm.fontSize() / 20);
                            }
                            transform += 'translateY(-' + px + 'px)';
                            textareaElm.style.transform = transform;
                            textareaElm.style.height = 'auto';
                            textareaElm.style.height = textareaElm.scrollHeight + 3 + 'px';
                            textareaElm.onkeydown = (e) => {
                                if (e.key === 'Enter') {
                                    textareaElm.blur();
                                }
                            };
                            textareaElm.onblur = async () => {
                                const content = textareaElm.value;
                                if (content !== '')
                                    await update(
                                        child(this.boardDB, currentTextKey + '/properties'),
                                        { content }
                                    );
                                else textareaElm.focus();
                                textareaElm.style.display = 'none';
                                tranformElm.show();
                                textElm.show();
                                stageElm.draw();
                            };
                            textareaElm.focus();
                        });
                    }
                }
            });
        }
    };
    textMode = async () => {
        const textareaElm = this.textareaRef.current;
        const tranformElm = this.tranformRef.current;
        const stageElm = this.stageRef.current;
        const x = (stageElm.getPointerPosition().x - stageElm.getX()) / stageElm.scaleX();
        const y = (stageElm.getPointerPosition().y - stageElm.getY()) / stageElm.scaleY();
        const { stroke, strokeWidth } = this.state;
        const currentTextKey = push(this.boardDB).key;
        const currentTextValue = {
            properties: {
                userId: this.state.user.uid,
                type: 'text',
                lock: false,
                content: '',
                x,
                y,
                width: 300,
            },
            options: { stroke, strokeWidth },
        };
        await update(this.boardDB, { [currentTextKey]: currentTextValue });
        const filter = (node) => {
            console.log(node.getAttr('id'), currentTextKey);
            return node.getAttr('id') === currentTextKey;
        };
        this.changeMode(this.selectMode);
        const textElm = this.layerRef.current.getChildren(filter)[0];
        tranformElm.nodes([textElm]);
        textElm.hide();
        console.log(2);
        tranformElm.hide();
        let textPosition = textElm.getAbsolutePosition();
        let areaPosition = {
            x:
                (stageElm.container().offsetLeft + textPosition.x - stageElm.getX()) /
                stageElm.scaleX(),
            y:
                (stageElm.container().offsetTop + textPosition.y - stageElm.getY()) /
                stageElm.scaleY(),
        };
        textareaElm.value = textElm.text();
        textareaElm.style.display = 'block';
        textareaElm.style.position = 'absolute';
        textareaElm.style.top = areaPosition.y + 'px';
        textareaElm.style.left = areaPosition.x + 'px';
        textareaElm.style.width = textElm.width() - textElm.padding() * 2 + 'px';
        textareaElm.style.height = textElm.height() - textElm.padding() * 2 + 5 + 'px';
        textareaElm.style.fontSize = textElm.fontSize() + 'px';
        textareaElm.style.border = 'none';
        textareaElm.style.padding = '0px';
        textareaElm.style.margin = '0px';
        textareaElm.style.overflow = 'hidden';
        textareaElm.style.background = 'none';
        textareaElm.style.outline = 'none';
        textareaElm.style.resize = 'none';
        textareaElm.style.lineHeight = textElm.lineHeight();
        textareaElm.style.fontFamily = textElm.fontFamily();
        textareaElm.style.transformOrigin = 'left top';
        textareaElm.style.textAlign = textElm.align();
        textareaElm.style.color = textElm.fill();
        const rotation = textElm.rotation();
        let transform = '';
        if (rotation) {
            transform += 'rotateZ(' + rotation + 'deg)';
        }
        let px = 0;
        let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
            px += 2 + Math.round(textElm.fontSize() / 20);
        }
        transform += 'translateY(-' + px + 'px)';
        textareaElm.style.transform = transform;
        textareaElm.style.height = 'auto';
        textareaElm.style.height = textareaElm.scrollHeight + 3 + 'px';
        textareaElm.onkeydown = (e) => {
            if (e.keyCode === 13) {
                textareaElm.blur();
            }
        };
        textareaElm.onblur = async () => {
            const content = textareaElm.value;
            if (content !== '') {
                await update(child(this.boardDB, currentTextKey + '/properties'), {
                    content,
                });
            } else {
                await remove(child(this.boardDB, currentTextKey));
            }
            textareaElm.style.display = 'none';
            tranformElm.show();
            textElm.show();
            stageElm.draw();
        };
        textareaElm.focus();
    };
    imageMode = async () => {
        const fileImportElm = this.fileImportRef.current;
        const tranformElm = this.tranformRef.current;
        const layerElm = this.layerRef.current;
        const stageElm = this.stageRef.current;
        const x = (stageElm.getPointerPosition().x - stageElm.getX()) / stageElm.scaleX();
        const y = (stageElm.getPointerPosition().y - stageElm.getY()) / stageElm.scaleY();
        const onFileLoaded = async (e) => {
            let match = /^data:(.*);base64,(.*)$/.exec(e.target.result);
            let mimeType = match[1];
            if (mimeType.includes('image')) {
                const currentImageKey = await push(this.boardDB).key;
                const currentImageValue = {
                    properties: {
                        userId: this.state.user.uid,
                        base64: e.target.result,
                        type: 'image',
                        lock: false,
                        scaleX: 1,
                        scaleY: 1,
                        x,
                        y,
                    },
                    options: {},
                };
                await update(this.boardDB, { [currentImageKey]: currentImageValue });
                const filter = (node) => {
                    return node.getAttr('id') === currentImageKey;
                };
                tranformElm.nodes(layerElm.getChildren(filter));
            }
        };

        const handleFileSelect = (e) => {
            let files = e.target.files;
            let file = files[0];
            let reader = new FileReader();
            reader.onload = onFileLoaded;
            reader.readAsDataURL(file);
        };

        fileImportElm.onchange = (e) => handleFileSelect(e);
        fileImportElm.click();

        this.changeMode(this.selectMode);
    };
    eraserMode = async (_e) => {
        const stageElm = this.stageRef.current;
        stageElm.on('mousemove touchmove pointermove', (e) => {
            if (e.target.getAttr('id') !== '') {
                if (!this.state.lines[e.target.getAttr('id')].properties.lock) {
                    remove(child(this.boardDB, e.target.getAttr('id')));
                }
            }
        });
        stageElm.on('mouseup touchend pointerup', () => {
            stageElm.off('mousemove touchmove pointermove');
            stageElm.off('mouseup touchend pointerup');
        });
    };
    clearBoard = async () => {
        if (this.state.permission === 'admin' || this.state.permission === 'teacher') {
            const lines = this.state.lines;
            const tranformElm = this.tranformRef.current;
            tranformElm.nodes([]);

            for (let key in lines) {
                if (lines[key].properties.lock === false) {
                    lines[key] = null;
                }
            }
            await set(this.boardDB, lines);
        }
    };
    scaleOrDragEndUpdate = async (e) => {
        if (e.target.getClassName() === 'Line') {
            const points = e.target.points();
            await update(child(this.boardDB, e.target.getAttr('id')), { points });
        } else if (e.target.getClassName() === 'Text') {
            await update(child(this.boardDB, e.target.getAttr('id') + '/properties'), {
                x: e.target.getX(),
                y: e.target.getY(),
                width: e.target.getWidth(),
            });
        } else {
            await update(child(this.boardDB, e.target.getAttr('id') + '/properties'), {
                x: e.target.getX(),
                y: e.target.getY(),
                scaleX: e.target.getScaleX(),
                scaleY: e.target.getScaleY(),
            });
        }
    };
    scaleOrDragOnUpdate = (e) => {
        const stageElm = this.stageRef.current;
        const shape = e.target;
        if (e.target.getClassName() === 'Line') {
            const oldPoints = shape.points();
            const newPoints = [];
            for (let i = 0; i < oldPoints.length / 2; i++) {
                const point = shape.getAbsoluteTransform().point({
                    x: oldPoints[i * 2],
                    y: oldPoints[i * 2 + 1],
                });
                newPoints.push(
                    (point.x - stageElm.getX()) / stageElm.getScaleX(),
                    (point.y - stageElm.getY()) / stageElm.getScaleY()
                );
            }
            shape.points(newPoints);
            shape.scaleX(1);
            shape.scaleY(1);
            shape.x(0);
            shape.y(0);
        } else if (e.target.getClassName() === 'Text') {
            shape.width(e.target.width() * e.target.scaleX());
            e.target.scaleX(1);
            e.target.scaleY(1);
        }
    };
    componentDidMount = () => {
        //Event listener for elements
        const canvasContainerElm = this.canvasContainerRef.current;
        const tranformElm = this.tranformRef.current;
        const selectElm = this.selectRef.current;
        const unlockElm = this.unlockRef.current;
        const eraserElm = this.eraserRef.current;
        const stageElm = this.stageRef.current;
        const clearElm = this.clearRef.current;
        const layerElm = this.layerRef.current;
        const imageElm = this.imageRef.current;
        const textElm = this.textRef.current;
        const drawElm = this.drawRef.current;
        const lockElm = this.lockRef.current;
        const lineElm = this.lineRef.current;

        onAuthStateChanged(Auth, (user) => {
            if (user !== null) {
                get(child(this.userDB, user.uid)).then((snapshot) => {
                    if (snapshot.exists()) {
                        const classes = new Set(snapshot.val().classes);
                        if (classes.has(this.props.router.params.classId)) {
                            this.setState({ user });
                            const userdata = snapshot.val() || {};
                            onValue(this.boardDB, (snapshot) => {
                                const data = snapshot.val() || {};
                                this.setState({ lines: data });
                            });
                            this.setState({ permission: userdata.permission });
                            this.changeMode(this.drawMode);
                        } else {
                            window.location.href = '/home';
                        }
                    } else {
                        window.location.href = '/home';
                    }
                });
            } else {
                window.location.href = '/login';
            }
        });
        stageElm.scaleX(0.4);
        stageElm.scaleY(0.4);
        canvasContainerElm.onwheel = (e) => {
            if (e.wheelDeltaY > 0) {
                if (stageElm.scaleX() < 2) {
                    stageElm.scaleX(stageElm.scaleX() + 0.1);
                    stageElm.scaleY(stageElm.scaleY() + 0.1);
                }
            } else if (e.wheelDeltaY < 0) {
                if (stageElm.scaleX() > 0.4) {
                    stageElm.scaleX(stageElm.scaleX() - 0.1);
                    stageElm.scaleY(stageElm.scaleY() - 0.1);
                }
            }
        };
        eraserElm.onclick = () => this.changeMode(this.eraserMode);
        selectElm.onclick = () => this.changeMode(this.selectMode);
        imageElm.onclick = () => this.changeMode(this.imageMode);
        drawElm.onclick = () => this.changeMode(this.drawMode);
        textElm.onclick = () => this.changeMode(this.textMode);
        lineElm.onclick = () => this.changeMode(this.lineMode);
        clearElm.onclick = () => this.clearBoard();
        lockElm.onclick = async () => {
            if (tranformElm.node() === 0) return;
            const lines = this.state.lines;
            tranformElm.nodes().forEach((elm) => {
                lines[elm.attrs.id].properties.lock = true;
            });
            await update(this.boardDB, lines);
        };
        unlockElm.onclick = async () => {
            if (tranformElm.node() === 0) return;
            const lines = this.state.lines;
            tranformElm.nodes().forEach((elm) => {
                lines[elm.attrs.id].properties.lock = false;
            });
            await update(this.boardDB, lines);
        };

        //Event listener for firebase

        stageElm.width(canvasContainerElm.offsetWidth);
        stageElm.height(canvasContainerElm.offsetHeight);

        window.addEventListener(
            'resize',
            () => {
                stageElm.width(canvasContainerElm.offsetWidth);
                stageElm.height(canvasContainerElm.offsetHeight);
            },
            true
        );

        window.addEventListener('keydown', (e) => {
            const { code } = e;
            if (code === 'Delete') {
                const selectedElm = tranformElm.nodes();
                selectedElm.forEach((node) => {
                    if (this.state.lines[node.getAttr('id')].properties.lock === false) {
                        remove(child(this.boardDB, node.getAttr('id')));
                    }
                });
            }
            tranformElm.nodes([]);
            layerElm.batchDraw();
        });
    };

    render = () => {
        const [cursorX, cursorY] = this.state.cursorPos;
        // const [cursorStyle, setCursorStyle] = this.state.cursorStyle;
        return (
            <div className='ContainerBgr'>
                <div className='Container'>
                    <input type='file' id='file' className='hidden' ref={this.fileImportRef} />

                    <div className='Board-container relative' ref={this.canvasContainerRef}>
                        <p
                            className={
                                this.state.usernameOwnLine === ''
                                    ? ``
                                    : `absolute px-5 py-2 text-white bg-username-tag rounded-full z-10`
                            }
                            style={{ top: `${cursorY - 40}px`, left: `${cursorX + 5}px` }}
                        >
                            {this.state.usernameOwnLine}
                        </p>
                        <Stage
                            ref={this.stageRef}
                            onMouseMove={(evt) => {
                                if (this.state.showUsername === true) {
                                    this.setState({ cursorPos: [evt.evt.layerX, evt.evt.layerY] });
                                    if (evt.target.attrs.owner === undefined) {
                                        this.setState({ usernameOwnLine: '' });
                                    } else {
                                        get(child(this.userDB, evt.target.attrs.owner)).then(
                                            (snapshot) => {
                                                if (snapshot.exists()) {
                                                    this.setState({
                                                        usernameOwnLine: snapshot.val().displayName,
                                                    });
                                                } else {
                                                    this.setState({ usernameOwnLine: '' });
                                                }
                                            }
                                        );
                                    }
                                }
                            }}
                        >
                            <Layer ref={this.layerRef}>
                                {[
                                    ...Object.entries(this.state.lines),
                                    ['currentLine', this.state.currentLine],
                                ].map(([key, value], i) => {
                                    try {
                                        let return_value;
                                        if (value.properties.type === 'line') {
                                            if (value.points !== 0) {
                                                return_value = (
                                                    <Line
                                                        id={key}
                                                        tension={0.5}
                                                        strokeWidth={5}
                                                        key={'line' + i}
                                                        points={value.points}
                                                        x={value.properties.x}
                                                        y={value.properties.y}
                                                        type={value.properties.type}
                                                        stroke={value.options.stroke}
                                                        owner={value.properties.userId}
                                                        scaleX={value.properties.scaleX}
                                                        scaleY={value.properties.scaleY}
                                                        onDragEnd={this.scaleOrDragEndUpdate}
                                                    />
                                                );
                                            }
                                        } else if (value.properties.type === 'text') {
                                            let x = value.properties.x;
                                            let y = value.properties.y;
                                            return_value = (
                                                <Text
                                                    id={key}
                                                    fontSize={30}
                                                    key={'text' + i}
                                                    x={x}
                                                    y={y}
                                                    fill={value.options.stroke}
                                                    type={value.properties.type}
                                                    width={value.properties.width}
                                                    text={value.properties.content}
                                                    owner={value.properties.userId}
                                                    height={value.properties.height}
                                                    scaleX={value.properties.scaleX}
                                                    scaleY={value.properties.scaleY}
                                                    onDragEnd={this.scaleOrDragEndUpdate}
                                                />
                                            );
                                        } else if (value.properties.type === 'image') {
                                            if (value.properties.content !== '')
                                                return_value = (
                                                    <ImageKonva
                                                        id={key}
                                                        key={'image' + i}
                                                        x={value.properties.x}
                                                        y={value.properties.y}
                                                        type={value.properties.type}
                                                        owner={value.properties.userId}
                                                        scaleX={value.properties.scaleX}
                                                        scaleY={value.properties.scaleY}
                                                        base64={value.properties.base64}
                                                        onDragEnd={this.scaleOrDragEndUpdate}
                                                    />
                                                );
                                            else remove(child(this.boardDB, key));
                                        } else if (value.properties.type === 'math') {
                                            if (value.properties.content !== '')
                                                return_value = (
                                                    <ImageKonva
                                                        id={key}
                                                        key={'math' + i}
                                                        x={value.properties.x}
                                                        y={value.properties.y}
                                                        type={value.properties.type}
                                                        owner={value.properties.userId}
                                                        scaleX={value.properties.scaleX}
                                                        scaleY={value.properties.scaleY}
                                                        base64={value.properties.base64}
                                                        content={value.properties.content}
                                                        onDragEnd={this.scaleOrDragEndUpdate}
                                                    />
                                                );
                                            else remove(child(this.boardDB, key));
                                        }
                                        return return_value;
                                    } catch (e) {
                                        console.log(key);
                                        return <></>;
                                    }
                                })}
                                <Rect
                                    fill={'rgba(0,0,255,0.5)'}
                                    visible={false}
                                    ref={this.rectRef}
                                />
                                <Transformer
                                    ignoreStroke={true}
                                    ref={this.tranformRef}
                                    onTransform={this.scaleOrDragOnUpdate}
                                    onTransformEnd={this.scaleOrDragEndUpdate}
                                />
                                <Html>
                                    <textarea
                                        ref={this.textareaRef}
                                        style={{ display: 'none' }}
                                    ></textarea>
                                </Html>
                            </Layer>
                        </Stage>
                    </div>

                    <div className='Toolbar-container'>
                        <div id='Toolbar-icon-container' ref={this.selectRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/cursor.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container' ref={this.drawRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/pencil.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container' ref={this.lineRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/nodes.png')}
                                alt={'line'}
                            />
                        </div>
                        <div id='Toolbar-icon-container' ref={this.textRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/text.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container' ref={this.mathRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/math.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container' ref={this.eraserRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/eraser.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container'>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/rectangle.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container'>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/triangle.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container' ref={this.imageRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/image.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container' ref={this.lockRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/lock.png')}
                                alt={'Lock'}
                            />
                        </div>
                        <div id='Toolbar-icon-container' ref={this.unlockRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/unlock.png')}
                                alt={'Unlock'}
                            />
                        </div>
                        <div id='Toolbar-icon-container'>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/comment.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container'>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/party.png')}
                                alt={''}
                            />
                        </div>
                        <div id='Toolbar-icon-container-clear' ref={this.clearRef}>
                            <img
                                id='Toolbar-icon'
                                src={require('../../assets/Clear.png')}
                                alt={''}
                            />
                        </div>
                        {(this.state.permission === '' || this.state.permission === 'teacher') && (
                            // {(this.state.permission === "admin" || this.state.permission === "teacher") && (
                            <div
                                id='Toolbar-icon-container-clear'
                                ref={this.copyRef}
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        window.location.origin +
                                            '/join/' +
                                            this.props.router.params.classId
                                    );
                                }}
                            >
                                <img
                                    id='Toolbar-icon'
                                    src={require('../../assets/copy.png')}
                                    alt={''}
                                />
                            </div>
                        )}
                    </div>

                    <div className='titleContainer'>
                        <p id='title'>{this.props.router.params.classId}</p>
                    </div>

                    {/* <div className="Editbar-container">
                        <img id="Undo" src={r   equire("../../assets/redo.png")} alt={""} />
                        <img id="Redo" src={require("../../assets/redo.png")} alt={""} />
                        <h5>100%</h5>
                        <h5>Tuỳ chỉnh</h5>
                        <div id="color-container">
                            <input
                                type="color"
                                id="style2"
                                defaultValue={this.state.stroke}
                                onInput={(e) => {
                                    this.setState({ stroke: e.target.value });
                                }}
                            />
                        </div>
                    </div> */}
                    <div className={this.state.controlStatus}>
                        <div id='controlHeader'>
                            <p>SETTINGS</p>
                            <div
                                id='menuIconContainer'
                                onClick={() => {
                                    if (this.state.controlStatus === 'controlContainer-inactive') {
                                        this.setState({ controlStatus: 'controlContainer-active' });
                                    } else if (
                                        this.state.controlStatus === 'controlContainer-active'
                                    ) {
                                        this.setState({
                                            controlStatus: 'controlContainer-inactive',
                                        });
                                    }
                                }}
                            >
                                <img src={require('../../assets/menu.png')} alt='' />
                            </div>
                        </div>
                        <div id='themeContainer'>
                            <p>Theme</p>
                            <div id='themeSelector'></div>
                        </div>
                        <div id='textContainer'>
                            <p>Text</p>
                            <div id='textSettings'>
                                <select id='selectFont'>
                                    <option value='option1'>Arial</option>
                                    <option value='option2' defaultValue>
                                        Time new roman
                                    </option>
                                    <option value='option3'>Comfotaa</option>
                                </select>

                                <div id='WeightAndSize'>
                                    <select id='selectFontWeight'>
                                        <option value='option1'>Light</option>
                                        <option value='option2' defaultValue>
                                            Normal
                                        </option>
                                        <option value='option3'>Bold</option>
                                    </select>

                                    <select id='selectFontSize'>
                                        <option value='option1'>2</option>
                                        <option value='option2' defaultValue>
                                            4
                                        </option>
                                        <option value='option3'>6</option>
                                        <option value='option3'>8</option>
                                        <option value='option3'>10</option>
                                        <option value='option3'>12</option>
                                        <option value='option3'>14</option>
                                        <option value='option3'>16</option>
                                        <option value='option3'>18</option>
                                        <option value='option3'>20</option>
                                    </select>
                                </div>

                                <div id='selectColor'>
                                    <button id='white'></button>
                                    <button id='orange'></button>
                                    <button id='purple'></button>
                                    <button id='yellow'></button>
                                    <button id='green'></button>
                                </div>
                            </div>
                        </div>
                        <div id='shapeContainer'>
                            <p>Brush</p>
                            <div id='shapeSettings'>
                                <div id='selectStroke'>
                                    <p>Stroke</p>
                                    <select id='selectStrokeSize'
                                            onChange={(e) => this.setState({strokeWidth: e.target.value})}
                                            value={this.strokeWidth}
                                    >
                                        <option value='2'>2</option>
                                        <option value='4' defaultValue>
                                            4
                                        </option>
                                        <option value='6'>6</option>
                                        <option value='8'>8</option>
                                        <option value='10'>10</option>
                                        <option value='12'>12</option>
                                        <option value='14'>14</option>
                                        <option value='16'>16</option>
                                        <option value='18'>18</option>
                                        <option value='20'>20</option>
                                    </select>
                                </div>
                                <div id='selectColor'>
                                    {
                                        [["white", "#ffffff"], ["orange", "#fd7952"], ["purple", "#7b21cc"], ["yellow", "#e09132"]].map(([id, value]) => (
                                            <button type="button" id={id} onClick={() => {
                                                this.setState({ stroke: value })
                                            }}/>))
                                    }
                                    <div id='color-container'>
                                        <input
                                            type='color'
                                            id='style2'
                                            defaultValue={this.state.stroke}
                                            onChange={(e) => {
                                                this.setState({ stroke: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button id='keepOpen'>Keep Open</button>
                    </div>
                    <div className='userControlContainer'>
                        <div id='iconContainer'>
                            <img src={require('../../assets/group.png')} alt='' />
                        </div>
                        <p id='counter'>00 người đã tham gia</p>
                    </div>

                    <div
                        id='ChangeBotStatus'
                        onClick={() => {
                            if (this.state.chatBotStatus !== 'ChatContainer-active') {
                                this.setState({ chatBotStatus: 'ChatContainer-active' });
                            } else {
                                this.setState({ chatBotStatus: 'ChatContainer' });
                            }
                        }}
                        rel='noreferrer'
                    >
                        <img id='ChangeBotIcon' src={require('../../assets/chatbot.png')} alt='' />
                    </div> 
                    <ChatBot mode={this.state.chatBotStatus} />
                    {/* <ShowVideo /> */}
                </div>
            </div>
        );
    };
}

export default withRouter(Paper);
