import { child, get, push, ref, set, update } from "firebase/database";
import { Auth, Database } from "../../firebase.config";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect } from "react";
import "./index.css";

const SideNav = () => {
    return (
        <div className="SideNav_container">
            <div id="LogoContainer">
                <img id="logo" src={require("../../assets/logo.png")} alt={""} />
            </div>
            <div id="HomeBtn">
                <img id="logoBtn" src={require("../../assets/homeLogo.png")} alt={""} />
                <h2 id="BtnTitle">Trang chủ</h2>
            </div>
            <div id="UpComingBtn">
                <img id="logoBtn" src={require("../../assets/upComingLogo.png")} alt={""} />
                <h2 id="BtnTitle">Đang diễn ra</h2>
            </div>
            <div id="DoneBtn">
                <img id="logoBtn" src={require("../../assets/doneLogo.png")} alt={""} />
                <h2 id="BtnTitle">Đã hoàn thành</h2>
            </div>
            <div id="LogoutBtn">
                <img id="logoBtn" src={require("../../assets/logoutLogo.png")} alt={""} />
                <h2 id="BtnTitle">Đăng xuất</h2>
            </div>
        </div>
    );
};

const CreateClass = () => {
    const classNameRef = React.useRef(null);
    const selectionRef = React.useRef(null);
    const subjectRef = React.useRef(null);
    const roomRef = React.useRef(null);
    const [user, setUser] = React.useState(null);

    useEffect(() => {
        onAuthStateChanged(Auth, (user) => {
            const refDB = ref(Database, "users/" + user.uid);
            get(refDB).then((snapshot) => {
                if (user !== null)
                    if (snapshot.exists())
                        // if (snapshot.val().permission === "teacher" || snapshot.val().permission === "admin") setUser(user);
                        // else window.location.href = "/home";
                        setUser(user);
                    else window.location.href = "/home";
                else window.location.href = "/login";
            });
        });
    });
    const createClass = async () => {
        const refDB = ref(Database, "classes");
        const classkey = await push(refDB).key;
        await set(child(refDB, classkey), {
            className: classNameRef.current.value,
            selection: selectionRef.current.value,
            subject: subjectRef.current.value,
            room: roomRef.current.value,
            userId: user.uid,
            board: null,
        });

        get(ref(Database, "users/" + user.uid + "/classes")).then((snapshot) => {
            if (snapshot.exists())
                update(ref(Database, "users/" + user.uid), {
                    classes: [...snapshot.val(), classkey],
                });
            else
                update(ref(Database, "users/" + user.uid), {
                    classes: [classkey],
                });
            window.location.href = "/paper/" + classkey;
        });
    };
    return (
        <div className="Home-Container">
            <SideNav />
            <div className="Main-container">
                <h1 id="title">TẠO LỚP</h1>
                <h2 id="content">Tên lớp</h2>
                <input id="input" type={"text"} ref={classNameRef} />
                <h2 id="content">Tên lớp</h2>
                <input id="input" type={"text"} ref={selectionRef} />
                <h2 id="content">Môn học</h2>
                <input id="input" type={"text"} ref={subjectRef} />
                <h2 id="content">Tên phòng</h2>
                <input id="input" type={"text"} ref={roomRef} />
                <div id="Btns">
                    <button id="Btn" onClick={createClass}>
                        Tạo lớp
                    </button>
                    <button id="Btn" onClick={() => (window.location.href = "/home")}>
                        Hủy bỏ
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateClass;
