import { child, get, ref } from 'firebase/database';
import React, { useRef } from 'react';
import { Database } from '../../firebase.config';
import './index.css';

const SideNav = () => {
    return (
        <div className='SideNav_container'>
            <div id='LogoContainer'>
                <img id='logo' src={require('../../assets/logo.png')} alt={''} />
            </div>
            <div id='HomeBtn'>
                <img id='logoBtn' src={require('../../assets/homeLogo.png')} alt={''} />
                <h2 id='BtnTitle'>Trang chủ</h2>
            </div>
            <div id='UpComingBtn'>
                <img id='logoBtn' src={require('../../assets/upComingLogo.png')} alt={''} />
                <h2 id='BtnTitle'>Đang diễn ra</h2>
            </div>
            <div id='DoneBtn'>
                <img id='logoBtn' src={require('../../assets/doneLogo.png')} alt={''} />
                <h2 id='BtnTitle'>Đã hoàn thành</h2>
            </div>
            <div id='LogoutBtn'>
                <img id='logoBtn' src={require('../../assets/logoutLogo.png')} alt={''} />
                <h2 id='BtnTitle'>Đăng xuất</h2>
            </div>
        </div>
    );
};

// const Join_mainPart = () => {

// }

const Index = () => {
    const joinCodeRef = useRef(null);
    return (
        <div className='Home-container'>
            <SideNav />
            <div className='Main-container'>
                <input id='enterJoinCode' placeholder='Nhập mã lớp' ref={joinCodeRef} />
                <button
                    id='JoinBtn'
                    onClick={() => {
                        const refDb = ref(Database, 'classes');
                        get(child(refDb, joinCodeRef.current.value)).then((snapshot) => {
                            if (snapshot.exists()) {
                                window.location.href = '/join/' + joinCodeRef.current.value;
                            } else {
                                console.log('No data available');
                            }
                        });
                    }}
                >
                    Tham Gia
                </button>
                <button id='CancelBtn' onClick={() => {window.location.href = '/home'}}>Hủy bỏ</button>
            </div>
        </div>
    );
};

export default Index;
