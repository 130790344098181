import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import LandingPage from "./components/LandingPage/index";
import CreateClass from "./components/CreateClass/index";
import Register from "./components/Register/index";
import Paper from "./components/Paper/index";
import Login from "./components/Login/index";
import Home from "./components/Home/index";
import Join from "./components/Join/index";
import JoinClass from "./components/JoinClass/index";
// import Test from './components/Test/index';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route index element={<LandingPage />} />
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="home" element={<Home />} />
                    <Route path="/paper/:classId" element={<Paper />} />
                    <Route path="/join/:classId" element={<Join />} />
                    <Route path="/joinclass" element={<JoinClass />} />
                    <Route path="createclass" element={<CreateClass />} />
                    <Route path="*" element={<LandingPage />} />
                </Routes>
            </Router>

            {/* <LandingPage /> */}
            {/* <Login /> */}
            {/* <Home /> */}
        </div>
    );
}

export default App;
