import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyDtzMgNmlfjBewqOu_5_FOEe2jGeCFW8Sg',
    authDomain: 'f-broad.firebaseapp.com',
    databaseURL: 'https://f-broad-default-rtdb.firebaseio.com',
    projectId: 'f-broad',
    storageBucket: 'f-broad.appspot.com',
    messagingSenderId: '555800348827',
    appId: '1:555800348827:web:e1d6b7f44a560da3541e3e',
};

const app = initializeApp(firebaseConfig);
const Database = getDatabase();
const Auth = getAuth(app);
// connectDatabaseEmulator(Database, "rtdb.pandakewt.net", 443)

export { Database, Auth };
