import React from 'react';

// import Paper from "../Paper/index.js";

import { Auth } from '../../firebase.config.js';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const Index = () => {
    const Authentication = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(Auth, provider)
            .then(async () => {
                window.location.href = '/home';
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <div className='grid h-screen bg-gradient-to-r from-login-100 to-login-200 w-screen place-content-scretch p-32'>
            <div className='bg-login-container rounded-3xl p-10 grid grid-cols-2'>
                <div className='self-end'>
                    <h1 className='text-5xl text-white font-bold m-5'>Đăng Ký</h1>
                    <div className='text-2xl self-end'>
                        <p className='text-login-text mx-5 font-bold'>Tạo tài khoản riêng của bạn</p>
                        <div className='w-4/6 relative group mx-5 my-16'>
                            <input className='w-full peer p-4 block bg-transparent border-b-2 text-white outline-none' type='text' id='email' required />
                            <label
                                htmlFor='email'
                                className='text-white text-2xl transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0'
                            >
                                Email
                            </label>
                        </div>
                        <div className='w-4/6 relative group mx-5 my-16'>
                            <input className='w-full peer p-4 block bg-transparent border-b-2 text-white outline-none' type='password' id='password' required />
                            <label
                                htmlFor='password'
                                className='text-white text-2xl transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0'
                            >
                                Password
                            </label>
                        </div>
                        <div className='text-white mx-5 my-20 space-x-10 flex justify-center w-4/6'>
                            <button className='rounded-3xl bg-login-btn p-5' onClick={Authentication}>
                                Đăng ký
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
