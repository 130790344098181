import { GoogleGenerativeAI, HarmCategory, HarmBlockThreshold } from "@google/generative-ai";
import { useState, useEffect, useRef } from 'react';

export default function ChatBot({ mode }) {
    const [history, setHistory] = useState([]);
    const [question, setQuestion] = useState('')
    // const [showChatBgr, setShowChatBgr] = useState(false);

    const chatboxRef = useRef();

    const genAI = new GoogleGenerativeAI('AIzaSyCWej3vASqP4HvGY9Cq0afauvf7vt2yTMk');
    const safetySettings = [
        {category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT, threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE},
        {category: HarmCategory.HARM_CATEGORY_HARASSMENT, threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE},
        {category: HarmCategory.HARM_CATEGORY_HATE_SPEECH, threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE},
        {category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT, threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE},
        {category: HarmCategory.HARM_CATEGORY_UNSPECIFIED, threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE}
    ]
    const model = genAI.getGenerativeModel({ model: "gemini-pro", safetySettings});
    const chat = model.startChat({
        generationConfig: {
            maxOutputTokens: 500,
        },
    });

    async function handleData() {
        try {
            setHistory([...history, {role: 'Người Dùng', content: question}])
            const result = await chat.sendMessage(question);
            const response = await result.response;
            const text = response.text();
            setHistory([...history, {role: 'Người Dùng', content: question}, {role: 'AI', content: text}])
        } catch {
            setHistory([...history, {role: 'Người Dùng', content: question}, {role: 'AI', content: 'Điều bạn tìm' +
                    ' kiếm không hợp lý, hãy thử câu hỏi khác'}])
        }

    }

    useEffect(() => {
        const answerContainer = document.getElementById("AnswerContainer");
        if (answerContainer) {
            answerContainer.scrollTop = answerContainer.scrollHeight;
        }
    }, [history]);

    // Use useEffect to show the ChatBgr after 3 seconds
    // useEffect(() => {
    //     setShowChatBgr(true);
    // }, []);

    // Use another useEffect to hide the ChatBgr after 3 seconds if mode is "ChatContainer-active"
    // useEffect(() => {
    //     if (mode === "ChatContainer-active") {
    //         const hideTimer = setTimeout(() => {
    //             setShowChatBgr(false);
    //         }, 4000); // Hide the ChatBgr after 3 seconds
    //
    //         return () => {
    //             clearTimeout(hideTimer); // Clear the hide timer if the component unmounts or mode changes
    //         };
    //     }
    // }, [mode]);

    return (
        <div className={mode}>
            <div id="chat-TopBar">
                <div id="chat-TopBar-LogoContainer">
                    <img src={require("../../assets/chatbot.png")} alt="" />
                </div>
                <p>F-Broad chatbot</p>
            </div>
            <textarea readOnly={true} id="AnswerContainer" value={history.map((message) => `${message.role}: ${message.content}\n`).join("")} />
            <div id="chat-input">
                <textarea
                    id="chat-input-textarea"
                    placeholder="Nhập câu hỏi..."
                    ref={chatboxRef}
                    defaultValue={question}
                    onChange={(text) => setQuestion(text.target.value)}
                />

                <div id="chat-input-sendContainer" onClick={() => handleData()}>
                    <img src={require("../../assets/chat-sendBtn.png")} alt="" />
                </div>
            </div>
            {/*{showChatBgr && <img id="ChatBgr" alt="" src={require("../../assets/robot.gif")} />}*/}
        </div>
    );
}