import { onAuthStateChanged } from 'firebase/auth';
import { get, ref, update } from 'firebase/database';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Auth, Database } from '../../firebase.config';

const JoinClass = () => {
    const param = useParams();
    // const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        onAuthStateChanged(Auth, (user) => {
            if (user !== null) {
                // console.log(param);
                get(ref(Database, 'users/' + user.uid + '/classes')).then((snapshot) => {
                    if (snapshot.exists()) {
                        if (snapshot.val().includes(param.classId)) {
                            window.location.href = '/paper/' + param.classId;
                        } else {
                            update(ref(Database, 'users/' + user.uid), {
                                classes: [...snapshot.val(), param.classId],
                            });
                            window.location.href = '/paper/' + param.classId;
                        }
                    } else
                        update(ref(Database, 'users/' + user.uid), {
                            classes: [param.classId],
                        });
                    window.location.href = '/paper/' + param.classId;
                });
            } else {
                window.location.href = '/login';
            }
        });
    });
};

export default JoinClass;
