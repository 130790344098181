import React from 'react';
import './index.css';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { Auth, Database } from '../../firebase.config';
import { useEffect } from 'react';
import { ref, get, child } from 'firebase/database';

const SideNav = () => {
    const logout = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                window.location.href = '/';
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <div className='SideNav_container'>
            <div id='LogoContainer'>
                <img id='logo' src={require('../../assets/logo.png')} alt={''} />
            </div>
            <div id='HomeBtn'>
                <img id='logoBtn' src={require('../../assets/homeLogo.png')} alt={''} />
                <h2 id='BtnTitle'>Trang chủ</h2>
            </div>
            <div id='UpComingBtn'>
                <img id='logoBtn' src={require('../../assets/upComingLogo.png')} alt={''} />
                <h2 id='BtnTitle'>Đang diễn ra</h2>
            </div>
            <div id='DoneBtn'>
                <img id='logoBtn' src={require('../../assets/doneLogo.png')} alt={''} />
                <h2 id='BtnTitle'>Đã hoàn thành</h2>
            </div>
            <div id='LogoutBtn' onClick={logout}>
                <img id='logoBtn' src={require('../../assets/logoutLogo.png')} alt={''} />
                <h2 id='BtnTitle'>Đăng xuất</h2>
            </div>
        </div>
    );
};

const JoinedClassChild = ({ className, owner, boardId }) => {
    return (
        <div
            id='JoinedClassChild'
            onClick={() => {
                window.location.href = '/paper/' + boardId;
            }}
        >
            {/* <div id="JoinedClassChild_part1" /> */}
            <h2 id='ClassTitle'>{className}</h2>
            <h3 id='ClassOwner'>{owner}</h3>
        </div>
    );
};

const MainPart = () => {
    const [classes, setClass] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            onAuthStateChanged(Auth, async (user) => {
                if (user !== null) {
                    const userRefDB = ref(Database, 'users/' + user.uid);
                    const userDB = await get(userRefDB);
                    if (userDB.exists()) {
                        let classes = [];
                        try {
                            for (let i in [...(userDB.val()?.classes || [])]) {
                                const boardRefDB = ref(
                                    Database,
                                    'classes/' + userDB.val()?.classes[i]
                                );
                                const boardNameDB = await get(child(boardRefDB, 'className'));
                                const boardOwnerDB = await get(child(boardRefDB, 'userId'));
                                const boardUsersDB = await get(
                                    child(ref(Database, 'users'), boardOwnerDB.val())
                                );
                                classes.push({
                                    boardId: userDB.val()?.classes[i],
                                    classname: boardNameDB.val(),
                                    username: boardUsersDB.val()?.displayName,
                                });
                            }
                            setClass(classes);
                        } catch (err) {
                            console.log(err);
                        }
                    }
                } else {
                    window.location.href = '/login';
                }
            });
        }
    }, [loaded]);

    return (
        <div className='Main_Container'>
            <div id='MyClassContainer'>
                {classes.map((item) => {
                    return (
                        <JoinedClassChild
                            className={item.classname}
                            owner={item.username}
                            boardId={item.boardId}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const Home = () => {
    return (
        <div className='Home-Container'>
            <div
                id='JoinClass'
                onClick={() => {
                    window.location.href = '/joinclass';
                }}
            >
                <h3>Tham gia</h3>
            </div>
            <div
                id='CreateClass'
                onClick={() => {
                    window.location.href = '/createclass';
                }}
            >
                <h3>Tạo lớp</h3>
            </div>
            <SideNav />
            <MainPart />
        </div>
    );
};

export default Home;
