import React from "react";
import $ from "jquery";
import "./index.css";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Auth } from "../../firebase.config";
import { useState } from "react";

let words = ["   CHÀO MỪNG ĐẾN VỚI", "    F - BROAD"],
    part,
    i = 0,
    offset = 0,
    len = words.length,
    forwards = true,
    skip_count = 0,
    skip_delay = 15,
    speed = 70;

const wordflick = function () {
    setInterval(function () {
        if (forwards) {
            if (offset >= words[i].length) {
                ++skip_count;
                if (skip_count === skip_delay) {
                    forwards = false;
                    skip_count = 0;
                }
            }
        } else {
            if (offset === 0) {
                forwards = true;
                i++;
                offset = 0;
                if (i >= len) {
                    i = 0;
                }
            }
        }
        part = words[i].substr(0, offset);
        if (skip_count === 0) {
            if (forwards) {
                offset++;
            } else {
                offset--;
            }
        }
        $(".word").text(part);
    }, speed);
};

$(document).ready(function () {
    wordflick();
});

const LandingPage = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (isLoading) {
            onAuthStateChanged(Auth, (user) => {
                setIsLoading(false);
                if (user !== null) setIsLogin(true);
            });
        }
    }, [isLoading]);
    return (
        <div className="main-container">
            <div className="sub-container">
                <div className="sky">
                    <div className="stars"></div>
                    <div className="stars2"></div>
                    <div className="stars3"></div>
                    <div className="comet"></div>
                </div>
            </div>

            <div className="word"></div>

            <button
                className="glow-on-hover"
                type="button"
                onClick={() => {
                    if (isLogin) window.location.href = "/home";
                    else window.location.href = "/login";
                }}
            >
                BẮT ĐẦU
            </button>
        </div>
    );
};

export default LandingPage;
